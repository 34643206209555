export enum EventType {
    SERVICE_ACCESSED = 'SERVICE_ACCESSED',
    AUTOMATED_ORDER = 'AUTOMATED_ORDER',
    NUM_PROGRAMS_CHANGED = 'NUM_PROGRAMS_CHANGED',
    PROGRAM_DETAILS_VIEWED = 'PROGRAM_DETAILS_VIEWED',
    PROGRAM_PINNED = 'PROGRAM_PINNED',
    PROGRAM_UNPINNED = 'PROGRAM_UNPINNED',
    PROGRAM_SELECTED = 'PROGRAM_SELECTED',
    PROGRAM_DESELECTED = 'PROGRAM_DESELECTED',
    CONDITIONS_IMPORTED = 'CONDITIONS_IMPORTED',
    DEMOGRAPHICS_CHANGED = 'DEMOGRAPHICS_CHANGED',
    DISABILITY_CHANGED = 'DISABILITY_CHANGED',
    ALERT_PROGRAMS_AVAILABLE = 'ALERT_PROGRAMS_AVAILABLE',
    ALERT_PROGRAM_INACTIVE = 'ALERT_PROGRAM_INACTIVE',
    ALERT_FUNDING_STATUS_NO_LONGER_ACTIVE = 'ALERT_FUNDING_STATUS_NO_LONGER_ACTIVE',
    ALERT_CALCULATED_ASSISTANCE_AMOUNT_CHANGED = 'ALERT_CALCULATED_ASSISTANCE_AMOUNT_CHANGED',
    PROGRAM_MAX_LOAN_AMOUNT_CHANGED = 'PROGRAM_MAX_LOAN_AMOUNT_CHANGED',
    FINAL_DISPOSITION_CHANGED = 'FINAL_DISPOSITION_CHANGED'
}

export const eventTypeDisplay = {
    [EventType.SERVICE_ACCESSED]: 'Service Accessed',
    [EventType.AUTOMATED_ORDER]: 'Automated Order',
    [EventType.NUM_PROGRAMS_CHANGED]: 'Number of Programs Changed',
    [EventType.PROGRAM_DETAILS_VIEWED]: 'Program Details Viewed',
    [EventType.PROGRAM_PINNED]: 'Program Pinned',
    [EventType.PROGRAM_UNPINNED]: 'Program Unpinned',
    [EventType.PROGRAM_SELECTED]: 'Program Selected',
    [EventType.PROGRAM_DESELECTED]: 'Program Deselected',
    [EventType.CONDITIONS_IMPORTED]: 'Conditions Imported',
    [EventType.DEMOGRAPHICS_CHANGED]: 'Demographics Changed',
    [EventType.DISABILITY_CHANGED]: 'Disability Changed',
    [EventType.ALERT_PROGRAMS_AVAILABLE]: 'Alert: Programs Available',
    [EventType.ALERT_PROGRAM_INACTIVE]: 'Alert: Program Inactive',
    [EventType.ALERT_FUNDING_STATUS_NO_LONGER_ACTIVE]: 'Alert: Funding Status No Longer Active',
    [EventType.ALERT_CALCULATED_ASSISTANCE_AMOUNT_CHANGED]: 'Alert: Calculated Assistance Amount Changed',
    [EventType.PROGRAM_MAX_LOAN_AMOUNT_CHANGED]: 'Program Max Loan Amount Changed',
    [EventType.FINAL_DISPOSITION_CHANGED]: 'Final Disposition Changed'
};
