import { DialogContent, MenuItem } from '@mui/material';
import {
    Button,
    DateField,
    Dialog, DialogActions, TextField, useForm, usePageMessage
} from '@tsp-ui/core';
import { addDays } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import {
    EventResultParams, getEventResults
} from '../../api';
import { EventType, eventTypeDisplay } from '../../api/enums/EventType';

import styles from './EventResultsDialog.module.scss';


interface EventResultsDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function EventResultsDialog({ open, onClose }: EventResultsDialogProps) {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<EventResultParams>();

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);
        try {
            const filteredParams = Object.fromEntries(
                Object.entries(formData).filter(([ _, value ]) => value !== undefined
                    && value !== null
                    && value !== '')
            );

            if (filteredParams.endDate) {
                const endDate: Date = new Date(filteredParams.endDate);
                filteredParams.endDate = addDays(endDate, 1).toISOString();
            }

            const results = await getEventResults(filteredParams);
            console.log('Event Results:', results.records);
            onClose();
            formMethods.reset();
            pageMessage.success('Event results retrieved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching event results', error);
        } finally {
            setLoading(false);
        }
    });

    const handleClose = useCallback(() => {
        formMethods.reset();
        onClose();
    }, [ formMethods, onClose ]);

    return (
        <Dialog
            title="Event Results"
            loading={loading}
            open={open}
            onClose={handleClose}
        >
            <DialogContent className={styles.content}>
                <form
                    noValidate
                    id="query-data-form"
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    <FormProvider {...formMethods}>
                        <TextField<EventResultParams>
                            name="orgId"
                            label="Organization"
                        />

                        <TextField<EventResultParams>
                            name="instanceId"
                            label="Instance"
                        />

                        <TextField<EventResultParams>
                            name="loanId"
                            label="Loan ID"
                        />

                        <TextField<EventResultParams>
                            name="eventType"
                            label="Event Type"
                            select
                        >
                            {Object.values(EventType).map((eventType) => (
                                <MenuItem
                                    key={eventType}
                                    value={eventType}
                                >
                                    {eventTypeDisplay[eventType]}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField<EventResultParams>
                            name="persona"
                            label="Persona"
                        />

                        <DateField<EventResultParams>
                            name="startDate"
                            label="Start Date"
                        />

                        <DateField<EventResultParams>
                            name="endDate"
                            label="End Date"
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    form="query-data-form"
                    type="submit"
                    disabled={loading}
                >
                    Search
                </Button>
            </DialogActions>
        </Dialog>
    );
}
